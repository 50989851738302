import { render, staticRenderFns } from "./member-protocol.vue?vue&type=template&id=64f4d086&scoped=true&"
import script from "./member-protocol.vue?vue&type=script&lang=ts&"
export * from "./member-protocol.vue?vue&type=script&lang=ts&"
import style0 from "./member-protocol.vue?vue&type=style&index=0&id=64f4d086&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f4d086",
  null
  
)

export default component.exports