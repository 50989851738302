










































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "member-protocol"
})
export default class memberProtocol extends Vue {}
